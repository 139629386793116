<template>
    <div class="ks-right-bar" data-auto-height>
        <div class="ks-wrapper d-flex flex-direction-column">
            <div class="p-3 d-flex flex-direction-row align-items-center" style="border-bottom: 1px solid rgba(57, 81, 155, .2);">
                <div class="m-0 flex-1 text-ellipsis fw-500">{{!getData ? 'Create New Contact' : getData.title}}</div>
                <a href="javascript:void(0);" class="right-bar-toggle p-0 opacity-5" style="font-size: 2.5em; line-height: 0; color: #000;">&times;</a>
            </div>
            <div class="alert bg-success text-white br-0 m-0" v-if="loading"><span class="la la-spin la-spinner mr-2"></span> Data is being loaded, please wait ...</div>
            <div class="position-relative flex-1">
                <div class="scroll">
                    <form>
                        <h5 class="mx-4 my-4">About this Contact</h5>

                        <div class="row p-2 m-0">
                            <div class="form-group col-12">
                                <label>Company Name</label>
                                <div class="input-group input-icon icon-right icon icon-lg" :class="{'dropdown':!getData || transfer}">
                                    <span class="input-group-prepend d-flex align-items-center text-danger" style="font-size: 2em;" v-if="data.hasOwnProperty.call(data, 'warning')" data-toggle="tooltip" title="Partner, End User or Vendor not existing in partner lists.">
                                        <i class="la la-exclamation-circle" ></i>
                                    </span>
                                    <input type="text" class="form-control" v-model="data.customer_name" :disabled="getData" v-if="getData && !transfer">
                                    <input type="text" class="form-control" placeholder="Search Partner ..." ref="s2" @keyup="search2($event)" @focus="search2($event)" data-toggle="dropdown" autocomplete="off" :disabled="loading" v-else>
                                    <span class="icon-addon" v-if="!getData || transfer">
                                        <span class="la la-search" v-if="!s2Load"></span>
                                        <span class="la la-spin la-spinner" v-else></span>
                                    </span>
                                    <div class="dropdown-menu w-100" v-if="!getData || transfer">
                                        <ul class="m-0 p-0">
                                            <li class="dropdown-item cp" v-for="(s, si) in s2" :key="si" @click="selectS2(s)">{{s.name}} ({{si}})</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12">
                                <label>Contact Name</label>
                                <input type="text" class="form-control" v-model="data.contact_name" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Position</label>
                                <input type="text" class="form-control" v-model="data.position" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Email</label>
                                <input type="text" class="form-control" v-model="data.email" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Telephone #1</label>
                                <input type="text" class="form-control" v-model="data.telno1" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Telephone #2</label>
                                <input type="text" class="form-control" v-model="data.telno2" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Mobile #1</label>
                                <input type="text" class="form-control" v-model="data.celno1" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Mobile #2</label>
                                <input type="text" class="form-control" v-model="data.celno2" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Mobile #3</label>
                                <input type="text" class="form-control" v-model="data.celno3" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Mobile #4</label>
                                <input type="text" class="form-control" v-model="data.celno4" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Facebook</label>
                                <input type="text" class="form-control" v-model="data.facebook" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Skype</label>
                                <input type="text" class="form-control" v-model="data.skype" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Viber</label>
                                <input type="text" class="form-control" v-model="data.viber" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Twitter</label>
                                <input type="text" class="form-control" v-model="data.twitter" :disabled="!data.editable && getData">
                            </div>

                            <div class="form-group col-12">
                                <label>Birthday</label>
                                <input type="date" class="form-control" v-model="data.bday" :disabled="!data.editable && getData">
                            </div>
                        </div>
                    </form>
                </div>
                <!-- <div class="w-100 h-100 d-flex justify-content-center align-items-center" v-else>
                    <img src="/assets/img/loaders/ardent-loader.gif" class="opacity-3">
                </div> -->
            </div>
            <div class="p-3 d-flex flex-direction-row align-items-center justify-content-between" style="border-top: 1px solid rgba(57, 81, 155, .2);">
                <div class="d-flex flex-row">
                    <jl-button class="btn mx-1" :disabled="loading" v-if="!loading && (data.editable !== false || getData === false)" :eventClick="update" :class="{'btn-success':!getData,'btn-dark':getData}">{{!getData ? 'Create' : 'Update'}}</jl-button>
                    <button class="btn btn-default mx-1 right-bar-toggle">Close</button>
                </div>
                <button class="btn" :class="{'btn-warning':!transfer,'btn-default':transfer}" data-toggle="tooltip" title="Transfer contact to another company" @click="transfer = !transfer" v-if="!loading && data.editable !== false && getData !== false">{{!transfer ? 'Change Company' : 'Cancel Company'}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['getData'],
    data() {
        return {
            loading: false,
            data: {},
            s2: {},
            ccode: '',
            s2Load: false,
            transfer: false
        }
    },
    computed: {
        
    },
    methods: {
        open() {
            let body = document.body;
            this.data = {};
            this.s2 = {};
            this.ccode = '';
            this.s2Load = false;
            this.transfer = false;
            if(body.classList.contains('ks-right-bar-open')) {
                body.classList.remove('ks-right-bar-open');
            }
            else body.classList.add('ks-right-bar-open');
        },
        async load() {
            this.loading = true;
            this.data = await this.API.contact(this.getData.id);
            this.loading = false;
        },
        async update(e) {
            let id = (!this.getData ? '' : this.getData.id);
            if(id == '' || this.transfer) this.data.customer_code = this.s2v;
            if(this.transfer) this.data.transfer_mode = true;
            
            await this.API.contact_post(this.data, id)
                    .then(res => {
                        if(id == '') this.open();
                        if(this.transfer) this.transfer = false;
                        this.$jlalert.show(res.data, res.status !== 200);
                    })
                    .catch(err => {
                        this.$jlalert.show(err);
                    });

            this.$jlbutton.unload(e);
        },
         async search2(e) {
            let value = e.target.value;
            if(value == '') {
                this.s2 = [];
                this.s2v = '';
                return;
            }

            if(this.s2Load) {
                this.queue++;
                return;
            }
            this.s2Load = true;

            this.s2 = await this.$axios.get(`/partners/search?pmp=true&search=${value}`)
                    .then(res => {
                        return res.data;
                    })
                    .catch(() => {
                        return {};
                    });
           this.s2Load = false;
           if(this.queue) {
               this.queue = 0;
               this.search2(e);
           }
        },
        selectS2(data) {
            if(typeof data == 'object') {
                this.$refs.s2.value = data.name;
                this.s2v = data.code;
                this.s2 = [];
            }
        },
    },
    mounted() {
        
    },
    watch: {   
        "getData": function(n) {
            this.data = {};
            this.open();
            console.log(n);
            if(Object.keys(n).length) {
                this.load();
            }
        },
        "loading": function(n) {
            if(n) this.data = {};
        }
    }
}
</script>

<style scoped>
label {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 0.8;
}

.dropdown .dropdown-menu {
    border: solid 1px #e6e6e6;
}

.dropdown .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #f9f9f9;
}
</style>