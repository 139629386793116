<template>
    <div class="ks-body position-relative flex-1" v-if="!isLoading && data.result">
        <div class="scroll">
            <table class="ks-table table table-hover">
                <thead>
                    <th v-for="(h, hi) in header" :key="hi">{{h}}</th>
                </thead>
                <tbody v-if="data.result">
                    <tr v-for="(result, index) in data.result" :key="index" @click="callback(index)">
                        <td v-for="(rr, ri) in result" :key="ri">
                            <div v-html="rr"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="ks-body position-relative flex-1 justify-content-center align-items-center d-flex flex-column" v-else-if="!isLoading && !data.result">
        <h6 class="opacity-5">No data found</h6>
    </div>
    <div class="ks-body position-relative flex-1 justify-content-center align-items-center d-flex flex-column" v-else>
        <img src="/assets/img/loaders/ardent-loader.gif" class="opacity-3">
    </div>
    <div class="ks-header ks-footer">
        <div class="ks-actions">
            <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-outline-primary ks-light ks-no-text" @click="!isLoading ? prev() : ''" :disabled="isLoading || !Object.keys(data).length || (!data.page ? 0 : data.page) <= 1">
                    <span class="la la-angle-left ks-icon"></span>
                </button>
                <button type="button" class="btn btn-outline-primary ks-light ks-no-text" @click="!isLoading ? next() : ''" :disabled="isLoading || !Object.keys(data).length || (!data.page ? 0 : data.page) >= (!data.total_page ? 0 : data.total_page)">
                    <span class="la la-angle-right ks-icon"></span>
                </button>
            </div>
            <button class="btn btn-outline-primary ks-light ks-no-text" :disabled="isLoading" @click="!isLoading ? load() : ''">
                <span class="la la-refresh ks-icon"></span>
            </button>
            <span class="text-bold">
                <b>{{!data.start ? 0 : data.start}}</b> - <b>{{!data.end ? 0 : data.end}}</b> 
                of <b>{{!data.total ? 0 : data.total}}</b> record(s) 
                Page: <select :disabled="!data.total_page && !data.total || isLoading" v-model="data.page" @change="data.page = $event.target.value; if(typeof change === 'function') change($event.target.value);">
                            <option :value="p" v-for="p in getpages" :key="p" >{{p}}</option>
                        </select>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['rowFN','headerLink','bodyLink','isLoaded','change','mounted'],
    data() {
        return {
            blink: '',
            isLoading: false,
            header: [],
            data: {}
        }
    },
    computed: {
         getpages() {
            let pages = [];
            if(this.data.total_page) {
                for (let index = 1; index <= this.data.total_page; index++) {
                    pages.push(index);
                }
            }

            return pages;
        }
    },
    methods: {
        next() {
            this.data.page = parseInt(this.data.page) + 1;
            if(this.data.page > this.data.total_page) {
                this.data.page = parseInt(this.data.page) - 1;
                return;
            }
            if(typeof this.change === 'function') this.change(this.data.page);

            //this.load();
        },
        prev() {
            this.data.page = parseInt(this.data.page) - 1;
            if(this.data.page < 1) {
                this.data.page = parseInt(this.data.page) + 1;
                return;
            }
            if(typeof this.change === 'function') this.change(this.data.page);

            //this.load();
        },
        bodyHolder() {
            return eval(this.blink);
        },
        headerHolder() {
            return eval(this.headerLink);
        },
        async loadHeader() {
            this.header = await this.headerHolder.call(this);
            this.load();
        },
        async load() {
            if(this.isLoading) return;
            this.isLoading = true;
            if(typeof this.isLoaded === 'function') this.isLoaded(this.isLoading);
            this.data =  await this.bodyHolder.call(this);
            this.isLoading = false;
            if(typeof this.isLoaded === 'function') this.isLoaded(this.isLoading);
        },
        callback(index) {
            if(typeof this.rowFN !== 'function') return;
            this.rowFN({[index]:this.data.result[index]});
        },
    },
    mounted() {
        if(typeof this.mounted === 'function') this.mounted(this);

        this.$emitter.on('tpage_refresh', (instance) => {
            instance.load();
        });

        this.blink = this.bodyLink;
        this.loadHeader();
    },
    watch: {
        "bodyLink": function(n) {
            if(n) {
                this.blink = n;
                this.load();
            }
        }
    }
}
</script>