<template>
    <div class="ks-emails ks-compact d-flex flex-direction-column">
        <div class="ks-header" v-if="$route.name !== 'duplicate'">
            <div class="d-flex flex-direction-row w-100">
                <div class="input-icon icon-right icon icon-lg dropdown flex-1 mr-2">
                    <input type="text" class="form-control" placeholder="Search Contact name ..." ref="s1" @keyup="search1($event);" @focus="search1($event)" data-toggle="dropdown" autocomplete="off" :disabled="isLoading">
                    <span class="icon-addon">
                        <span class="la la-search" v-if="!s1Load"></span>
                        <span class="la la-spin la-spinner" v-else></span>
                    </span>
                    <div class="dropdown-menu w-100">
                        <ul class="m-0 p-0">
                            <li class="dropdown-title" v-if="Object.keys(s1).length">Suggested</li>
                            <li class="dropdown-item cp" v-for="(s, si) in s1" :key="si" @click="selectS1(s)">{{s.name}}</li>
                        </ul>
                    </div>
                </div>
                <div class="input-icon icon-right icon icon-lg dropdown flex-1 mr-2">
                    <input type="text" class="form-control" placeholder="Search Partner ..." ref="s2" @keyup="search2($event)" @focus="search2($event)" data-toggle="dropdown" autocomplete="off" :disabled="isLoading">
                    <span class="icon-addon">
                        <span class="la la-search" v-if="!s2Load"></span>
                        <span class="la la-spin la-spinner" v-else></span>
                    </span>
                    <div class="dropdown-menu w-100">
                        <ul class="m-0 p-0">
                            <li class="dropdown-title" v-if="Object.keys(s2).length">Suggested</li>
                            <li class="dropdown-item cp" v-for="(s, si) in s2" :key="si" @click="selectS2(s)">{{s.name}} ({{si}})</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="ks-actions">
                <button class="btn btn-danger" @click="$refs.s1.value = '';$refs.s2.value = '';s1v = '';s2v = '';s1 = {};s2 = {};" >Clear Filter</button>
            </div>
            <div class="ks-actions">
                <button class="btn btn-dark" @click="exec" :disabled="isLoading">Search</button>
            </div>
        </div>

        <TablePage :rowFN="openSidebar" :headerLink="`this.API.loadContacts({}, '${routen()}', true)`" :bodyLink="`this.API.loadContacts({page:${page}, name:'${s1v}',partner:'${s2v}'}, '${routen()}')`" :isLoaded="loaded" :change="change" :mounted="tableMounted"/>
    </div>
    

    <SidebarContact :getData="selected" />
</template>

<script>
import TablePage from '@/views/subview/TablePage';
import SidebarContact from '@/views/subview/SidebarContact';

export default {
    components: {
        TablePage,
        SidebarContact
    },
    data() {
        return {
            s1: {},
            s1Load: false,
            s1v: '',
            s2: {},
            s2v: '',
            s2Load: false,
            pmp_flag: false,
            selected: '',
            isLoading: false,
            page: 1,
            tableInstance: {},
            old: '',
        }
    },
    methods: {
        routen() {
            if(this.$route.fullPath.includes('contact')) this.old = this.$route.name;
            return this.old;
        },
        tableMounted(instance) {
            this.tableInstance = instance;
        },
        exec() {
            this.isLoading = true;
            this.s1v = this.$refs.s1.value;
            this.s2v = this.$refs.s2.value;
            this.page = 1;
            this.$emitter.emit('tpage_refresh', this.tableInstance);
        },
        async search1(e) {
            let value = e.target.value;
            
            if(value == '') {
                this.s1 = [];
                this.s1v = '';
                return;
            }

            if(this.s1Load) {
                this.queue++;
                return;
            }
            this.s1Load = true;

            this.s1 = await this.$axios.get(`/contact/search?search=${value}`)
                    .then(res => {
                        return res.data;
                    })
                    .catch(() => {
                        return {};
                    });
           this.s1Load = false;
           if(this.queue) {
               this.queue = 0;
               this.search1(e);
           }
        },
        selectS1(data) {
            if(typeof data == 'object') {
                this.page = 1;
                this.$refs.s1.value = data.name;
                this.s1v = data.name;
                this.s1 = [];
            }
        },
        async search2(e) {
            let value = e.target.value;
            if(value == '') {
                this.s2 = [];
                this.s2v = '';
                return;
            }

            if(this.s2Load) {
                this.queue++;
                return;
            }
            this.s2Load = true;

            this.s2 = await this.$axios.get(`/partners/search?search=${value}`)
                    .then(res => {
                        return res.data;
                    })
                    .catch(() => {
                        return {};
                    });
           this.s2Load = false;
           if(this.queue) {
               this.queue = 0;
               this.search2(e);
           }
        },
        selectS2(data) {
            if(typeof data == 'object') {
                this.page = 1;
                this.$refs.s2.value = data.name;
                this.s2v = data.name;
                this.s2 = [];
            }
        },
        openSidebar(data) {
            if(data) {
                let dom = new DOMParser().parseFromString(data[Object.keys(data)[0]], 'text/html');
                let ksname = dom.querySelector('.ks-name').innerHTML;

                this.selected = {title:ksname,id:Object.keys(data)[0]}
            }
            else this.selected = data;
        },
        loaded(flag = false) {
            this.isLoading = flag;
        },
        change(page) {
            console.log(page);
            this.page = page;
            this.isLoading = true;
        },
        searching(e) {
            if(e.keyCode == 13) {
                this.page = 1;
                this.search = e.target.value;
            }
        }
    },
    mounted() {
       this.API.iniClick();
       this.$emitter.on('contact_sidebar', (res) => {
           this.openSidebar(res);
       })
    },
}
</script>